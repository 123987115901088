<template>
  <el-dialog
      title="分配品牌经销商"
      width="35%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 20%" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="分配公司 :" prop="tenantTenant">
            <el-select v-model="form.tenantCrop" filterable placeholder="公司" clearable size="medium">
              <el-option
                  v-for="item in tenantCropArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "lib-brand-distribution",
  data() {
    return {
      form: {
        libBrandId:this.libBrand.id,
        tenantCrop: ""
      },
      tenantCropArray:[],
      rules: {
        tenantCrop: [
          {required: true, message: '请输入选择经销商', trigger: 'change'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    libBrand:{
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryTenantCropArray();
    console.log(this.libBrand)
  },
  methods: {
    //检查订单校验
    updateCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.updateBrand();
        }
      })
    },
    //添加店铺
    updateBrand: function () {
      let value = `是否将 ${this.libBrand.brandName} 分配给此公司?`
      this.$confirm(value, '分配品牌:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "PUT",
          url: "/libraryBrandTenant/addBrandTenant",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    queryTenantCropArray(){
      this.$selectUtils.queryTenantCrops().then(response=>{
        this.tenantCropArray=JSON.parse(response.data.data);
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>