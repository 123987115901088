<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.brandAddState = true" style="margin-bottom: 10px">添加品牌</el-button>
      <el-table
          :data="table.libBrandList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'descending'}"
          style="width: 100%">
        <el-table-column prop="brandName" label="品牌名称" align="center" />
        <el-table-column prop="createDate" label="添加时间" align="center"/>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="warning" size="mini" v-if="scope.row.isDefault!==1" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="success" size="mini" v-if="scope.row.isDefault!==1" @click="openDistribution(scope.row)">分配经销商</el-button>
              <el-button type="primary" size="mini" v-if="scope.row.isDefault!==1" @click="openBrandTenantList(scope.row)">查看经销商</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <libBrandAdd v-if="dialog.brandAddState" :state="dialog.brandAddState" @close="close"/>
    <libBrandEdit v-if="dialog.brandEditState" :libBrand="dialog.libBrand" :state="dialog.brandEditState" @close="close"/>
    <libBrandDistribution v-if="dialog.distributionState" :libBrand="dialog.libBrand" :state="dialog.distributionState" @close="close"/>
    <libBrandTenantList v-if="dialog.brandTenantState" :libBrand="dialog.libBrand" :state="dialog.brandTenantState" @close="close"/>
  </div>
</template>

<script>
import libBrandAdd from "@/pages/share/lib-brand/lib-brand-add";
import libBrandEdit from "@/pages/share/lib-brand/lib-brand-edit";
import libBrandDistribution from "@/pages/share/lib-brand/lib-brand-distribution";
import libBrandTenantList from "@/pages/share/lib-brand/lib-brand-tenant-list";

export default {
  name: "lib-brand-list",
  data() {
    return {
      table: {
        libBrandList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        brandAddState: false,
        brandEditState: false,
        distributionState: false,
        brandTenantState: false,
        libBrand: {},
      }

    }
  },
  components: {
    libBrandAdd, libBrandEdit,libBrandDistribution,libBrandTenantList,
  },
  created() {
    this.queryLibBrandList();
  },
  methods: {
    //查询客户列表
    queryLibBrandList: function () {
      this.$axios({
        method: "GET",
        url: "/libraryBrand/queryList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
        }
      }).then(response => {

        this.table.total = response.data.data.total;
        this.table.libBrandList = response.data.data.list;
        console.log(this.table.libBrandList)
      })
    },
    openEdit(data){
      this.dialog.brandEditState = true
      this.dialog.libBrand = data;
    },
    //打开编辑店铺
    openDistribution(data) {
      this.dialog.distributionState = true
      this.dialog.libBrand = data
    },
    openBrandTenantList(data){
      this.dialog.brandTenantState = true
      this.dialog.libBrand = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryLibBrandList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryLibBrandList()
    },
    close: function (state) {
      this.dialog.brandAddState = state;
      this.dialog.brandEditState = state;
      this.dialog.distributionState = state;
      this.dialog.brandTenantState = state;
      this.queryLibBrandList();
    },
  }
}
</script>

<style scoped>

</style>